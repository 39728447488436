<template>
  <div class="game-container">
    <OveturnPuzzleGame
      :bgImg="bgImg"
      :imgList="imgList"
      :puzzleBgImg="puzzleBgImg"
      :mapList="mapList"
      :lessonNo="36"
    />
  </div>
</template>

<script>
import OveturnPuzzleGame from "@/components/Course/GamePage/OveturnPuzzleGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/03-Backgrounds/level-1-chapter-2-background-blue.svg"),
      puzzleBgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-base.svg"),

      mapList: [
        [
          { index: 1, id: 1 },
          { index: 4, id: 4 },
          { index: 7, id: 7 },
        ],
        [
          { index: 2, id: 2 },
          { index: 5, id: 5 },
          { index: 8, id: 8 },
        ],
        [
          { index: 3, id: 3 },
          { index: 6, id: 6 },
          { index: 9, id: 9 },
        ],
      ],
      imgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-1.svg"),
          id: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-2.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-3.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-4.svg"),
          id: 4,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-5.svg"),
          id: 5,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-6.svg"),
          id: 6,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-7.svg"),
          id: 7,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-8.svg"),
          id: 8,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G58-overturn-puzzle/lesson-36/puzzle-9.svg"),
          id: 9,
        },
      ],
    };
  },
  components: {
    OveturnPuzzleGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>

